import wurd from 'wurd-react';
import BillingPlugin from 'plugins/billing';
import Title from 'components/title';
import Block from 'components/block';

const cms = wurd.block('valetOrder.billing');

export default function ({ nextStep, mode, data }) {
  return (
    <Block>
      <Title cms={cms.block('title')} className="mt-5" />
      <BillingPlugin
        mode={mode}
        siteId={''}
        onSuccess={() => nextStep({ pay: '✓', session_id: undefined, _n: undefined }, { replace: true })}
      />
    </Block>
  )
}
