import wurd from 'wurd-react';
import BillingPlugin from 'plugins/billing';
import Title from 'components/title';
import Block from 'components/block';

const cms = wurd.block('unitOrder.billing');

export default function UnitOrderBillingStep({ nextStep, order, site }) {
  return (
    <Block>
      <Title cms={cms.block('title')} className="mt-5" />
      <BillingPlugin
        mode="setup"
        siteId={site.id}
        isReady={Boolean(order)}
        onSuccess={() => nextStep({ pay: '✓', session_id: undefined, _n: undefined }, { replace: true })}
      />
    </Block>
  )
}
